
import { getStudentsBasedOnRole, registerStudent } from "@/api/student";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import { Classroom } from "@/entities/classroom";
import { StudentGrade, StudentProperty } from "@/entities/student";
import store from "@/store";
import { generateRandomPassword, validPassword } from "@/utils/password";
import { Options, Vue } from "vue-class-component";
import { generateNewStudentId } from "@/utils/student";
import { validEmail } from "@/utils/email";
import { CustomProperty } from "@/entities/custom_property";
import { saveErrorLog } from "@/api/error";
import { Student } from "../../entities/student";
import { Getter } from "@/store/helper";
import { getClassRoomsBasedOnRole } from "@/api/classroom";
import { sortClassroomsById } from "@/utils/classroom";
import { validateUniqueGmail, validateUniqueMicrosoftEmail } from "@/api/role";

type ClassSchedule = {
  weekday: string;
  start: string;
  end: string;
};

type EmailReceivers = {
  email: string;
};

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon,
    MSelectBox,
    MTextField,
    MTextArea
  },
  emits: ["close", "downloadCsvTemplate", "registerCsvUpload"],
  props: {
    classrooms: Array,
    customProperties: Array
  }
})
export default class MsAddModal extends Vue {
  name = "";
  enteredName = false;
  selectClassroomId = "";
  classrooms: Classroom[] = [];
  customProperties: CustomProperty[] = [];
  characteristic = "";
  studentId = "";
  studentLoginId = "";
  password = "";
  selectGrade: StudentGrade = "その他";
  grades: StudentGrade[] = [
    "小1",
    "小2",
    "小3",
    "小4",
    "小5",
    "小6",
    "中1",
    "中2",
    "中3",
    "高1",
    "高2",
    "高3",
    "その他"
  ];
  classSchedules: ClassSchedule[] = [];
  emailReceivers: EmailReceivers[] = [];
  propertyDatas: {
    property: CustomProperty;
    value: string;
  }[] = [];
  weekdays: string[] = ["月曜", "火曜", "水曜", "木曜", "金曜", "土曜", "日曜"];
  enteredEmail = [];
  gmail = "";
  microsoftEmail = "";
  validEmail = validEmail;

  @Getter("isServiceProvider") isServiceProvider!: boolean;

  get classRooms() {
    const classRooms = getClassRoomsBasedOnRole(
      this.isServiceProvider,
      this.classrooms,
      store.state.school!.ref.id
    );
    return sortClassroomsById(classRooms);
  }

  get selectClassroom(): Classroom | null {
    const matchClassrooms = this.classRooms.filter(
      item => item.ref.id === this.selectClassroomId
    );
    if (matchClassrooms.length === 0) {
      return null;
    }
    return matchClassrooms[0];
  }

  get schoolLoginId(): string {
    if (!store.state.school) {
      return "";
    }
    return store.state.school.data.id;
  }

  get defaultLoginId(): string {
    return `${this.schoolLoginId}-${this.studentId}`;
  }

  get validName() {
    return this.name.length > 0;
  }

  get validClassroom() {
    return this.selectClassroom !== null;
  }

  get validStudentId() {
    const students: Student[] = getStudentsBasedOnRole(
      this.isServiceProvider,
      store.state.students,
      store.state.school!.ref.id
    );
    if (students.map(item => item.data.id).includes(this.studentId)) {
      return false;
    }
    return /^[0-9a-zA-Z]{3,}$/.test(this.studentId);
  }

  get validstudentLoginId(): boolean {
    if (!this.studentLoginId) return this.validStudentId;
    return (
      /^[\x21-\x7E]+$/.test(this.studentLoginId) &&
      String(this.studentLoginId).length >= 5
    );
  }

  get validPassword() {
    return validPassword(this.password);
  }

  get validClassSchedules() {
    for (const schedule of this.classSchedules) {
      if (schedule.weekday.length === 0) {
        return false;
      }
      if (!schedule.start.match(/^\d{2}:\d{2}$/g)) {
        return false;
      }
      if (!schedule.end.match(/^\d{2}:\d{2}$/g)) {
        return false;
      }
    }

    return true;
  }

  get validEmailReceivers() {
    for (const emailReceiver of this.emailReceivers) {
      if (!emailReceiver.email) {
        return false;
      }
      if (!this.validEmail(emailReceiver.email)) {
        return false;
      }
    }
    return true;
  }

  get validPropertyDatas() {
    for (const data of this.propertyDatas) {
      if (
        data.property.data.type === "select" &&
        data.value &&
        !data.property.data.choices.includes(data.value)
      ) {
        return false;
      }
    }
    return true;
  }

  get validGmail() {
    if (!this.gmail) return true;
    return this.validEmail(this.gmail);
  }

  get validMicrosoftEmail() {
    if (!this.microsoftEmail) return true;
    return this.validEmail(this.microsoftEmail);
  }

  get validData() {
    return (
      this.validName &&
      this.validClassroom &&
      this.validStudentId &&
      this.validstudentLoginId &&
      this.validPassword &&
      this.validClassSchedules &&
      this.validEmailReceivers &&
      this.validPropertyDatas &&
      this.validGmail &&
      this.validMicrosoftEmail
    );
  }

  addClassSchedule() {
    this.classSchedules.push({
      weekday: "",
      start: "",
      end: ""
    });
  }

  addEmailReceiver() {
    this.emailReceivers.push({ email: "" });
  }

  deleteSchedule(index: number) {
    this.classSchedules.splice(index, 1);
  }

  deleteEmailReceiver(index: number) {
    this.emailReceivers.splice(index, 1);
  }

  allEnter() {
    this.enteredName = true;
  }

  translateErrorMessage(e: unknown) {
    if (!(e instanceof Error)) return "予期せぬエラーが発生しました。";
    const { message } = e;
    if (message === "Gmail already in use") {
      return "既に使われているGoogleアカウントです";
    } else if (message === "Microsoft Email already in use") {
      return "既に使われているMicrosoftアカウントです";
    } else return message;
  }

  async registerStudent() {
    this.allEnter();

    if (
      !this.validData ||
      !store.state.role ||
      !store.state.school ||
      !this.selectClassroom
    ) {
      return;
    }

    store.commit("START_LOADING", "生徒登録中...");

    const classScheduleText = this.classSchedules.map(
      schedule => `${schedule.weekday} ${schedule.start} ~ ${schedule.end}`
    );

    const emailReceivers = this.emailReceivers.map(
      emailReceiver => emailReceiver.email
    );

    const properties: StudentProperty[] = this.propertyDatas.map(data => {
      let value: string | number;
      if (data.property.data.type === "number") {
        value = Number(data.value);
      } else {
        value = data.value;
      }
      return {
        id: data.property.ref.id,
        value
      };
    });

    try {
      const [isGmailUnique, isMicrosoftEmailUnique] = await Promise.all([
        (async () => {
          return !this.gmail
            ? Promise.resolve(true)
            : validateUniqueGmail(this.gmail);
        })(),
        (async () => {
          return !this.microsoftEmail
            ? Promise.resolve(true)
            : validateUniqueMicrosoftEmail(this.microsoftEmail);
        })()
      ]);

      if (!isGmailUnique) {
        throw new Error("Gmail already in use");
      }
      if (!isMicrosoftEmailUnique) {
        throw new Error("Microsoft Email already in use");
      }

      await registerStudent({
        classroomRef: this.selectClassroom.ref,
        id: this.studentId,
        loginId: this.studentLoginId.toString().trim(),
        name: this.name.trim(),
        grade: this.selectGrade,
        classSchedules: classScheduleText,
        emailReceivers,
        password: this.password,
        properties,
        characteristic: this.characteristic,
        gmail: this.gmail,
        microsoftEmail: this.microsoftEmail
      });
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`生徒の登録に失敗しました\n\n${this.translateErrorMessage(e)}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to register student"
      );
      return;
    }

    alert("生徒の登録が完了しました");

    this.$router.go(0);
  }

  downloadCsvTemplate() {
    this.$emit("downloadCsvTemplate");
  }

  close() {
    this.$emit("close");
  }

  created() {
    if (this.classRooms.length > 0) {
      this.selectClassroomId = this.classRooms[0].ref.id;
    }
    this.password = generateRandomPassword();

    this.studentId = generateNewStudentId(
      store.state.studentsOfSchool.map(item => item.data.id)
    );
    this.propertyDatas = this.customProperties.map(property => {
      return {
        property,
        value: ""
      };
    });
    this.studentLoginId = this.defaultLoginId;
  }
}
