import { roleCollectionKey } from "@/entities/role";
import firebase from "firebase/app";

export async function validateUniqueGmail(
  gmail: string,
  uid?: string
): Promise<boolean> {
  const snapshot = await firebase
    .firestore()
    .collection(roleCollectionKey)
    .get();
  if (snapshot.empty) return true;

  for (const doc of uid
    ? snapshot.docs.filter(
        doc => doc.exists && doc.data() && doc.ref.id !== uid
      )
    : snapshot.docs.filter(doc => doc.exists && doc.data())) {
    if (doc.data().gmail && doc.data().gmail === gmail) {
      return false;
    }
  }

  return true;
}

export async function validateUniqueMicrosoftEmail(
  microsoftEmail: string,
  uid?: string
): Promise<boolean> {
  const snapshot = await firebase
    .firestore()
    .collection(roleCollectionKey)
    .get();
  if (snapshot.empty) return true;

  for (const doc of uid
    ? snapshot.docs.filter(
        doc => doc.exists && doc.data() && doc.ref.id !== uid
      )
    : snapshot.docs.filter(doc => doc.exists && doc.data())) {
    if (
      doc.data().microsoftEmail &&
      doc.data().microsoftEmail === microsoftEmail
    ) {
      return false;
    }
  }

  return true;
}
