import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "w-full max-h-60 py-2 text-gray-700 text-sm font-medium bg-white shadow-lg rounded overflow-y-scroll"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full max-h-60 py-2 text-gray-700 text-sm font-medium bg-white shadow-lg rounded overflow-y-scroll"
}
const _hoisted_4 = {
  key: 2,
  class: "w-full max-h-60 py-2 text-gray-700 text-sm font-medium bg-white shadow-lg rounded overflow-y-scroll"
}
const _hoisted_5 = {
  key: 3,
  class: "w-full max-h-60 py-2 text-gray-700 text-sm font-medium bg-white shadow-lg rounded overflow-y-scroll"
}
const _hoisted_6 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_icon = _resolveComponent("m-icon")
  const _component_m_button = _resolveComponent("m-button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_m_button, {
      class: "w-full",
      color: "primary-500",
      "hover-color": "primary-600",
      disabled: _ctx.disabled,
      onClick: _ctx.changeShowModal
    }, {
      default: _withCtx(() => [
        _createVNode("p", null, _toDisplayString(_ctx.title), 1),
        (_ctx.showModal && !_ctx.conditionModalShowing)
          ? (_openBlock(), _createBlock(_component_m_icon, {
              key: 0,
              type: "chevron-up",
              class: "ml-2"
            }))
          : (_openBlock(), _createBlock(_component_m_icon, {
              key: 1,
              type: "chevron-down",
              class: "ml-2"
            }))
      ]),
      _: 1
    }, 8, ["disabled", "onClick"]),
    _createVNode("div", {
      class: [{ hidden: !_ctx.showModal }, _ctx.classes]
    }, [
      (_ctx.type === 'executeForSelectedStudents')
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            (!_ctx.isAdmin)
              ? (_openBlock(), _createBlock("button", {
                  key: 0,
                  class: "w-full px-2 py-2 text-left border-b hover:bg-gray-100",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.print('mingaku')))
                }, " みんがくログイン情報印刷 "))
              : _createCommentVNode("", true),
            (!_ctx.isAdmin)
              ? (_openBlock(), _createBlock("button", {
                  key: 1,
                  class: "w-full px-2 py-2 text-left border-b hover:bg-gray-100",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.print('schoolAI')))
                }, " スクールAIログイン情報印刷 "))
              : _createCommentVNode("", true),
            (!_ctx.isAdmin)
              ? (_openBlock(), _createBlock("button", {
                  key: 2,
                  class: "w-full px-2 py-2 text-left border-b hover:bg-gray-100",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.exportData && _ctx.exportData(...args)))
                }, " 学習データを出力 "))
              : _createCommentVNode("", true),
            _createVNode("button", {
              class: "w-full px-2 py-2 text-left border-b hover:bg-gray-100",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.exportStudentData && _ctx.exportStudentData(...args)))
            }, " 生徒データを出力 "),
            _createVNode("button", {
              class: "w-full px-2 py-2 text-left border-b hover:bg-gray-100",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.exportTodoData && _ctx.exportTodoData(...args)))
            }, " TODOデータを出力 "),
            _createVNode("button", {
              class: "w-full px-2 py-2 text-left border-b hover:bg-gray-100",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.exportLearningCardData && _ctx.exportLearningCardData(...args)))
            }, " 学習カード情報を出力 "),
            _createVNode("button", {
              class: "w-full px-2 py-2 text-left border-b hover:bg-gray-100",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.group && _ctx.group(...args)))
            }, " 生徒をグループ化 "),
            _createVNode("button", {
              class: "w-full px-2 py-2 text-left border-b hover:bg-gray-100",
              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.addReservation && _ctx.addReservation(...args)))
            }, " 学習予定を追加 "),
            _createVNode("button", {
              class: "w-full px-2 py-2 text-left border-b hover:bg-gray-100",
              onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.message && _ctx.message(...args)))
            }, " メッセージ送信 "),
            _createVNode("button", {
              class: "w-full px-2 py-2 text-left border-b hover:bg-gray-100",
              onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.createSchoolAIStudent && _ctx.createSchoolAIStudent(...args)))
            }, " スクールAIに連携する "),
            (_ctx.canEditStudent)
              ? (_openBlock(), _createBlock("button", {
                  key: 3,
                  class: "w-full px-2 py-2 text-left border-b hover:bg-gray-100",
                  onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.setStudentsRecess && _ctx.setStudentsRecess(...args)))
                }, " 休会に変更 "))
              : _createCommentVNode("", true),
            (_ctx.canEditStudent)
              ? (_openBlock(), _createBlock("button", {
                  key: 4,
                  class: "w-full px-2 py-2 text-left border-b hover:bg-gray-100",
                  onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.cancelStudentsRecess && _ctx.cancelStudentsRecess(...args)))
                }, " 休会を解除 "))
              : _createCommentVNode("", true),
            (_ctx.canEditStudent)
              ? (_openBlock(), _createBlock("button", {
                  key: 5,
                  class: "w-full px-2 py-2 text-left hover:bg-gray-100",
                  onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.deleteStudents && _ctx.deleteStudents(...args)))
                }, " 生徒削除 "))
              : _createCommentVNode("", true)
          ]))
        : (_ctx.type === 'executeForAllStudents')
          ? (_openBlock(), _createBlock("div", _hoisted_3, [
              (_ctx.isAdminOfSchool)
                ? (_openBlock(), _createBlock("button", {
                    key: 0,
                    class: "w-full px-2 py-2 text-left hover:bg-gray-100",
                    onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.moveUpGradesForAll && _ctx.moveUpGradesForAll(...args)))
                  }, " 学年を上げる "))
                : _createCommentVNode("", true),
              (_ctx.isAdminOfSchool)
                ? (_openBlock(), _createBlock("button", {
                    key: 1,
                    class: "w-full px-2 py-2 text-left hover:bg-gray-100",
                    onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.moveGradesDownForAll && _ctx.moveGradesDownForAll(...args)))
                  }, " 学年を下げる "))
                : _createCommentVNode("", true),
              (_ctx.isAdminOfSchool)
                ? (_openBlock(), _createBlock("button", {
                    key: 2,
                    class: "w-full px-2 py-2 text-left hover:bg-gray-100",
                    onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.createSchoolAiUserForAll && _ctx.createSchoolAiUserForAll(...args)))
                  }, " スクールAIに連携する "))
                : _createCommentVNode("", true),
              (_ctx.isAdminOfSchool)
                ? (_openBlock(), _createBlock("button", {
                    key: 3,
                    class: "w-full px-2 py-2 text-left hover:bg-gray-100",
                    onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.printMingakuLoginInfoForAll && _ctx.printMingakuLoginInfoForAll(...args)))
                  }, " みんがく帳票を印刷 "))
                : _createCommentVNode("", true),
              (_ctx.isAdminOfSchool)
                ? (_openBlock(), _createBlock("button", {
                    key: 4,
                    class: "w-full px-2 py-2 text-left hover:bg-gray-100",
                    onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.printSchoolAiLoginInfoForAll && _ctx.printSchoolAiLoginInfoForAll(...args)))
                  }, " スクールAI帳票を印刷 "))
                : _createCommentVNode("", true)
            ]))
          : (_ctx.type === 'addStudents')
            ? (_openBlock(), _createBlock("div", _hoisted_4, [
                (_ctx.isAdminOfSchool || _ctx.isSupervisorOfSchool || _ctx.isEmployeeOfSchool)
                  ? (_openBlock(), _createBlock("button", {
                      key: 0,
                      class: "w-full px-2 py-2 text-left hover:bg-gray-100",
                      onClick: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.startStudentRegistration && _ctx.startStudentRegistration(...args)))
                    }, " ひとりずつ登録 "))
                  : _createCommentVNode("", true),
                (_ctx.isAdminOfSchool || _ctx.isSupervisorOfSchool || _ctx.isEmployeeOfSchool)
                  ? (_openBlock(), _createBlock("button", {
                      key: 1,
                      class: "w-full px-2 py-2 text-left hover:bg-gray-100",
                      onClick: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.startMultiStudentRegistration && _ctx.startMultiStudentRegistration(...args)))
                    }, " CSV一括登録 "))
                  : _createCommentVNode("", true)
              ]))
            : (_ctx.type === 'editStudents')
              ? (_openBlock(), _createBlock("div", _hoisted_5, [
                  (_ctx.isAdminOfSchool || _ctx.isSupervisorOfSchool || _ctx.isEmployeeOfSchool)
                    ? (_openBlock(), _createBlock("button", {
                        key: 0,
                        class: "w-full px-2 py-2 text-left hover:bg-gray-100",
                        onClick: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.startEditingStudentsViaCsv && _ctx.startEditingStudentsViaCsv(...args)))
                      }, " 生徒情報を編集 "))
                    : _createCommentVNode("", true),
                  (_ctx.isAdminOfSchool)
                    ? (_openBlock(), _createBlock("button", {
                        key: 1,
                        class: "w-full px-2 py-2 text-left hover:bg-gray-100",
                        onClick: _cache[22] || (_cache[22] = 
//@ts-ignore
(...args) => (_ctx.startLinkingGoogle && _ctx.startLinkingGoogle(...args)))
                      }, " Google連携 "))
                    : _createCommentVNode("", true),
                  (_ctx.isAdminOfSchool)
                    ? (_openBlock(), _createBlock("button", {
                        key: 2,
                        class: "w-full px-2 py-2 text-left hover:bg-gray-100",
                        onClick: _cache[23] || (_cache[23] = 
//@ts-ignore
(...args) => (_ctx.startLinkingMicrosoft && _ctx.startLinkingMicrosoft(...args)))
                      }, " Microsoft連携 "))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createBlock("div", _hoisted_6))
    ], 2)
  ]))
}