
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import { Getter } from "@/store/helper";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MButton,
    MIcon
  },
  emits: [
    "onModalOpened",
    "onModalClosed",
    "export",
    "exportTodo",
    "exportLearningCard",
    "exportStudents",
    "group",
    "print",
    "message",
    "addReservation",
    "setStudentsRecess",
    "cancelStudentsRecess",
    "deleteStudents",
    "createSchoolAIStudent",
    "moveUpGradesForAll",
    "moveGradesDownForAll",
    "createSchoolAiUserForAll",
    "printMingakuLoginInfoForAll",
    "printSchoolAiLoginInfoForAll",
    "startStudentRegistration",
    "startEditingStudentsViaCsv",
    "startExportingAllStudents",
    "startMultiStudentRegistration",
    "openMicrosoftLinkageModal",
    "openGoogleLinkageModal"
  ],
  props: {
    type: String,
    disabled: Boolean,
    conditionModalShowing: Boolean,
    dropdownDirection: String
  },
  watch: {
    conditionModalShowing(showing: boolean) {
      if (!showing) return;
      this.forceCloseModal();
    }
  }
})
export default class MTableConditionItem extends Vue {
  type:
    | "executeForSelectedStudents"
    | "executeForAllStudents"
    | "addStudents"
    | "editStudents"
    | "" = "";
  disabled = false;
  showModal = false;
  conditionModalShowing = false;
  dropdownDirection: "up" | "down" = "up";

  get classes() {
    return this.dropdownDirection === "up"
      ? "absolute left-0 bottom-8 pt-4 z-10"
      : "absolute left-0 top-8 pb-4 z-10";
  }

  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;
  @Getter("isSupervisorOfSchool") isSupervisorOfSchool!: boolean;
  @Getter("isEmployeeOfSchool") isEmployeeOfSchool!: boolean;

  get canEditStudent(): boolean {
    return (
      this.isAdmin ||
      this.isAdminOfSchool ||
      this.isSupervisorOfSchool ||
      this.isEmployeeOfSchool
    );
  }

  get title(): string {
    if (this.type === "executeForSelectedStudents") {
      return "選択中の生徒に実行";
    } else if (this.type === "executeForAllStudents") {
      return "全生徒に実行";
    } else if (this.type === "addStudents") {
      return "生徒を登録";
    } else if (this.type === "editStudents") {
      return "まとめて編集";
    }
    return "";
  }

  changeShowModal() {
    if (this.disabled) {
      return;
    }
    this.showModal = !this.showModal;
    if (this.showModal) {
      this.$emit("onModalOpened", this.type);
    } else {
      this.$emit("onModalClosed", this.type);
    }
  }

  forceCloseModal() {
    this.showModal = false;
    this.$emit("onModalClosed", this.type);
  }

  print(target: "mingaku" | "schoolAI") {
    this.showModal = false;
    this.$emit("print", target);
  }

  group() {
    this.showModal = false;
    this.$emit("group");
  }

  message() {
    this.showModal = false;
    this.$emit("message");
  }

  createSchoolAIStudent() {
    this.showModal = false;
    this.$emit("createSchoolAIStudent");
  }

  addReservation() {
    this.showModal = false;
    this.$emit("addReservation");
  }

  setStudentsRecess() {
    this.showModal = false;
    this.$emit("setStudentsRecess");
  }

  cancelStudentsRecess() {
    this.showModal = false;
    this.$emit("cancelStudentsRecess");
  }

  deleteStudents() {
    this.showModal = false;
    this.$emit("deleteStudents");
  }

  exportData() {
    this.showModal = false;
    this.$emit("export");
  }

  exportStudentData() {
    this.showModal = false;
    this.$emit("exportStudents");
  }

  exportTodoData() {
    this.showModal = false;
    this.$emit("exportTodo");
  }

  exportLearningCardData() {
    this.showModal = false;
    this.$emit("exportLearningCard");
  }

  // 全生徒に実行系統
  moveUpGradesForAll() {
    this.showModal = false;
    this.$emit("moveUpGradesForAll");
  }

  moveGradesDownForAll() {
    this.showModal = false;
    this.$emit("moveGradesDownForAll");
  }

  createSchoolAiUserForAll() {
    this.showModal = false;
    this.$emit("createSchoolAiUserForAll");
  }

  printMingakuLoginInfoForAll() {
    this.showModal = false;
    this.$emit("printMingakuLoginInfoForAll");
  }

  printSchoolAiLoginInfoForAll() {
    this.showModal = false;
    this.$emit("printSchoolAiLoginInfoForAll");
  }

  //生徒情報登録/編集系統
  startStudentRegistration() {
    this.$emit("startStudentRegistration");
    this.forceCloseModal();
  }

  startMultiStudentRegistration() {
    this.$emit("startMultiStudentRegistration");
    this.forceCloseModal();
  }

  startEditingStudentsViaCsv() {
    this.$emit("startEditingStudentsViaCsv");
    this.forceCloseModal();
  }

  startExportingAllStudents() {
    this.$emit("startExportingAllStudents");
    this.forceCloseModal();
  }

  startLinkingGoogle() {
    this.$emit("openGoogleLinkageModal");
    this.forceCloseModal();
  }

  startLinkingMicrosoft() {
    this.$emit("openMicrosoftLinkageModal");
    this.forceCloseModal();
  }

  exportCsvTemplateForExternalProviderLinkage(val: "google" | "microsoft") {
    this.$emit("exportCsvTemplateForExternalProviderLinkage", val);
    this.forceCloseModal();
  }
}
